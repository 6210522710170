.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app-wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 64px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.app-wrapper > * {
  max-width: 480px;
}

.background-photo {
  width: 100%;
  object-fit: contain;
  overflow: hidden;
  margin-bottom: 16px;
}

.subtitle {
  display: flex;
  width: 100%;
}

.subtitle__expand {
  flex-grow: 1;
}

.string-variations {
  display: flex;
  flex-direction: column;
  font-size: 16px;  
  justify-content: center;
  color: lightgray;
}

.stopwatch {
  font-size: 16px; 
  margin-left: 8px; 
}